import React from "react"
import Layout from '../components/layout'
import { Link, graphql, useStaticQuery } from 'gatsby'
import BlogIndexCard from "../components/blog/blogIndexCard";
import { Helmet } from 'react-helmet'
import TrialCTA from "../components/trialCta";
import merchantOfferView from '../../public/Merchant-Offer-View.svg'

const BlogIndexPage = () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          blog {
            title
            description
            url
          }
        }
      }
      allGhostPost (sort: {order: DESC, fields: published_at}) {
        edges {
          node {
            id
            title
            visibility
            primary_author {
              name
              profile_image
              slug
            }
            feature_image
            excerpt
            reading_time
            slug
            url
            published_at(formatString: "MMMM Do, YYYY")
            primary_tag {
              name
              slug
            }
          }
        }
      }
    }
  `);

  return (
    <>
      <Helmet>
        {/* Meta */}
          <html lang="en" />
          <title>{data.site.siteMetadata.blog.title} — Checkout optimization articles, guides and resources</title>
          <meta charSet="utf-8" />
          <meta name="title" content={`Features - ${data.site.siteMetadata.blog.title}`}/>
          <meta name="description" content={data.site.siteMetadata.blog.description} /> 
          <link rel="canonical" href={data.site.siteMetadata.blog.url} />
      </Helmet>
      <Layout>
        <div className="relative px-4 pt-16 pb-20 bg-gray-50 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
            <div className="absolute inset-0">
              <div className="bg-white h-1/3 sm:h-2/3"/>
            </div>
            <div className="relative mx-auto max-w-7xl">
              <div className="text-center">
                <h1 className="text-3xl font-extrabold leading-9 tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                  <span className="text-peach-500 md:bg-clip-text md:text-transparent md:bg-gradient-to-r md:to-peach-500 md:from-indigo-700">
                    By the Batch Blog
                  </span>
                  
                </h1>
                <p className="max-w-2xl mx-auto mt-3 text-xl leading-7 text-gray-500 sm:mt-4">
                  {data.site.siteMetadata.blog.description}
                </p>
              </div>

              <div className="grid max-w-lg gap-5 mx-auto mt-12 lg:grid-cols-3 lg:max-w-none">
                {
                  data.allGhostPost.edges.map((post) => {
                    if (post.node.visibility === "public" && post.node.slug !== "data-schema") {
                      return(
                        <div className="flex flex-col overflow-hidden rounded-lg shadow-lg" id={`blog-card-${post.node.id}`}>
                          <div className="flex-shrink-0">
                            <Link to={`/${post.node.slug}`}><img className="object-cover w-full h-48" src={post.node.feature_image} alt=""/></Link>
                          </div>
                          <div className="flex flex-col justify-between flex-1 p-6 bg-white">
                            <div className="flex-1">
                                <Link to={`/${post.node.primary_tag.slug}`}>
                                  <span className="inline-flex items-center px-1.5 py-0.5 text-xs font-medium leading-5 bg-indigo-700 text-white">
                                      {post.node.primary_tag.name.toUpperCase()}
                                  </span>
                                </Link>
                              <Link to={`/${post.node.slug}`} className="block">
                                <h3 className="mt-2 text-xl font-semibold leading-7 text-gray-900">
                                  {post.node.title}
                                </h3>
                                <p className="mt-3 text-base leading-6 text-gray-500">
                                  {post.node.excerpt}
                                </p>
                              </Link>
                            </div>
                            <BlogIndexCard props={post.node} />
                          </div>
                        </div>
                      )
                    }
                  })
                }
              </div>
            </div>
          </div>
          <TrialCTA 
            bgClass={"bg-indigo-600"}
            title={"Increase sales with auction marketing"} 
            description={"Collect an unlimited number of offers and activate them for immediate revenue on the Forever Free plan."}
            buttonText={"Install Batch for free"} 
            url={"https://apps.shopify.com/batch-offers"} 
            image={merchantOfferView}
          />
      </Layout>
    </>
  )
}

export default BlogIndexPage
